/* eslint-disable @qonto/no-import-roles-constants */
import { ROLES } from 'qonto/constants/membership';

import ApplicationAbility from './application';

export default class InstantTransferLimitAbility extends ApplicationAbility {
  get canView() {
    return this.permissions.instant_transfer_limits.read;
  }

  get canUpdate() {
    return this.permissions.instant_transfer_limits.update;
  }

  canViewTargetMembership(targetMembership) {
    let isSelf = this.membership && this.membership.id === targetMembership.id;

    let targetRole = targetMembership.role;
    let selfRole = this.membership.role;

    if (!this.canView) {
      return false;
    }

    if (selfRole === ROLES.OWNER) {
      return true;
    }

    if (selfRole === ROLES.ADMIN) {
      return isSelf || targetRole === ROLES.MANAGER;
    }

    if (selfRole === ROLES.MANAGER) {
      return isSelf;
    }

    return false;
  }
}
