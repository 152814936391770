import { waitFor } from '@ember/test-waiters';

import { adapterAction } from '@mainmatter/ember-api-actions';

import ApplicationAdapter from 'qonto/adapters/application';
import { cardAcquirerNamespace } from 'qonto/constants/hosts';

export default class CardAcquirerMovementAdapter extends ApplicationAdapter {
  namespace = cardAcquirerNamespace;

  @waitFor
  async search(query, headers = {}) {
    let response = await adapterAction(this, 'movements', {
      method: 'POST',
      path: 'search',
      data: query,
      headers,
    });

    this.store.pushPayload({
      'card-acquirer-movement': response.movements,
    });

    let movements = response.movements.map(movement =>
      this.store.peekRecord('card-acquirer-movement', movement.id)
    );

    return { movements, meta: response.meta };
  }
}
